import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDepartmentusersList() {
  // Use toast
  const toast = useToast()

  const refDepartmentusersListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'nome', sortable: true },
    { key: 'email', sortable: true },
    { key: 'telefono', sortable: true },
    { key: 'ruolo', sortable: true },
    // { key: 'ruolo_reparto', sortable: true },
    { key: 'azioni' },
  ]
  const fields = {
    id: 'anagrafico_id',
    nome: 'diplay_name',
    email: 'email',
    telefono: 'phone_number',
    ruolo: 'role_code',
    // ruolo_reparto: 'department_role_code',
  }
  const perPage = ref(10)
  const totalDepartmentusers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refDepartmentusersListTable.value ? refDepartmentusersListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDepartmentusers.value,
    }
  })

  const refetchData = () => {
    refDepartmentusersListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchDepartmentusers = (ctx, callback) => {
    store
      .dispatch('app-departmentusers/fetchDepartmentusers', {
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        department_id: router.currentRoute.params.id,
      })
      .then(response => {
        const departmentusers = response.data[0]
        const total = response.data[1]

        callback(departmentusers)
        totalDepartmentusers.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleName = role => {
    if (role === 'user') return 'Utente'
    if (role === 'hr') return 'HR'
    if (role === 'reception_hub') return 'Reception HUB'
    if (role === 'reception_colle') return 'Reception COLLE'
    if (role === 'accountant') return 'Contabilità'
    if (role === 'admin') return 'Admin'
    return ''
  }

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'info'
    if (role === 'hr') return 'warning'
    if (role === 'reception_hub') return 'warning'
    if (role === 'reception_colle') return 'warning'
    if (role === 'accountant') return 'warning'
    if (role === 'admin') return 'danger'
    return 'danger'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'user') return 'UserIcon'
    if (role === 'hr') return 'UsersIcon'
    if (role === 'reception_hub') return 'UsersIcon'
    if (role === 'reception_colle') return 'UsersIcon'
    if (role === 'accountant') return 'UsersIcon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserXIcon'
  }

  const resolveDepartmentUserRoleName = role => {
    if (role === 'ufficio_hr') return 'Ufficio HR'
    if (role === 'responsabile') return 'Responsabile'
    if (role === 'capo_reparto') return 'Capo Reparto'
    return 'Utente'
  }

  return {
    fetchDepartmentusers,
    tableColumns,
    perPage,
    currentPage,
    totalDepartmentusers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDepartmentusersListTable,

    resolveUserRoleName,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveDepartmentUserRoleName,

    refetchData,
  }
}
