<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row class="pt-2">
          <b-col
            class="mb-4 d-flex justify-content-center align-items-center"
            cols="12"
          >
            <feather-icon
              icon="UserIcon"
              size="25"
              class="mr-2"
            />
            <h1>Utenti</h1>
          </b-col>
        </b-row>
        <!-- Table Top -->
        <!-- <b-row
          v-if="canManage"
          class="mb-4 d-flex align-items-end"
        >
          <b-col
            cols="12"
            md="4"
          >
            <label>Utente</label>
            <v-select
              v-model="add_user_id"
              :options="usersPaginated"
              :filterable="false"
              @open="onOpen"
              @close="onClose"
              @search="(query) => (search = query)"
            >
              <template #list-footer>
                <li
                  v-show="hasNextPage"
                  ref="load"
                  class="loader"
                >
                  Carica più Utenti...
                </li>
              </template>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <label>Ruolo Reparto</label>
            <v-select
              v-model="add_user_role_code"
              :options="roles"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-flex justify-content-center mt-2"
          >
            <b-button
              variant="primary"
              @click="addDepartmentuser"
            >
              Aggiungi
            </b-button>
          </b-col>
        </b-row> -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Cerca..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refDepartmentusersListTable"
        class="position-relative"
        :items="fetchDepartmentusers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessun Utente corrispondente trovato"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.anagrafico_id }}</span>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(nome)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="50"
                :src="data.item.avatar_file ? mediaUrl + data.item.avatar_file : ''"
                :text="avatarText(data.item.display_name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role_code)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.display_name }}
            </b-link>
            <small class="text-muted">@{{ data.item.identifier }}</small>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.email }}</span>
          </div>
        </template>

        <!-- Column: Telephone -->
        <template #cell(telefono)="data">
          <div class="text-nowrap">
            <feather-icon
              class="mr-1"
              :class="data.item.phone_number ? 'text-success' : 'text-danger'"
              :icon="data.item.phone_number ? 'PhoneIcon' : 'PhoneOffIcon'"
            />
            <span class="align-text-top">{{ data.item.phone_number }}</span>
          </div>
        </template>

        <template #cell(ruolo)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role_code)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role_code)}`"
            />
            <span class="align-text-top text-capitalize">{{ resolveUserRoleName(data.item.role_code) }}</span>
          </div>
        </template>

        <!-- <template #cell(ruolo_reparto)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              resolveDepartmentUserRoleName(data.item.department_role_code)
            }}</span>
          </div>
        </template> -->

        <!-- Column: Actions -->
        <template #cell(azioni)="data">
          <b-dropdown
            v-if="canManage"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="
                $bvModal.show('delete-modal')
                selectedDepartmentuser = data.item.id
              "
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Cancella</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDepartmentusers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="delete-modal"
        title="Cancella Utente Reparto"
        footer-class="d-flex justify-content-end"
        body-class="p-0"
        size="sm"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">
            Cancella Utente Reparto
          </h5>
          <feather-icon
            icon="XIcon"
            size="18"
            @click="close()"
          />
        </template>
        <p class="m-2">
          Sei sicuro di voler cancellare questo Utente dal Reparto?
        </p>
        <template #modal-footer="{ ok, cancel }">
          <b-button
            size="sm"
            variant="danger"
            @click="cancel()"
          >
            Annulla
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="
              ok()
              deleteDepartmentuser(selectedDepartmentuser)
            "
          >
            OK
          </b-button>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BLink,
  BImg,
  BButton,
  BModal,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import userStoreModule from '@/views/apps/user/userStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useDepartmentusersList from './useDepartmentusersList'
import departmentusersStoreModule from '../departmentusersStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BLink,
    BImg,
    BButton,
    BModal,
    BAvatar,

    vSelect,
  },
  props: {
    canManage: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const toast = useToast()

    const DEPARTMENTUSERS_APP_STORE_MODULE_NAME = 'app-departmentusers'
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(DEPARTMENTUSERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPARTMENTUSERS_APP_STORE_MODULE_NAME, departmentusersStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENTUSERS_APP_STORE_MODULE_NAME)) { store.unregisterModule(DEPARTMENTUSERS_APP_STORE_MODULE_NAME) }
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const users = ref(null)
    const _users = []
    store
      .dispatch('app-user/fetchUsers', { no_department: true, per_page: 1000 })
      .then(response => {
        response.data[0].forEach((user, i) => {
          const _user = {
            label: `#${user.anagrafico_id} - ${user.display_name}`,
            value: user.id,
          }
          _users.push(_user)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    users.value = _users

    const roles = [
      { value: 'capo_reparto', label: 'Capo Reparto' },
      { value: 'responsabile', label: 'Responsabile' },
      { value: 'ufficio_hr', label: 'Ufficio HR' },
    ]

    const mediaUrl = process.env.VUE_APP_MEDIA_URL

    const {
      fetchDepartmentusers,
      tableColumns,
      perPage,
      currentPage,
      totalDepartmentusers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDepartmentusersListTable,
      refetchData,

      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDepartmentUserRoleName,
    } = useDepartmentusersList()

    return {
      fetchDepartmentusers,
      tableColumns,
      perPage,
      currentPage,
      totalDepartmentusers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDepartmentusersListTable,
      refetchData,

      resolveUserRoleName,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveDepartmentUserRoleName,

      // Filter
      avatarText,
      mediaUrl,
      selectedDepartmentuser: null,
      add_user_id: null,
      add_user_role_code: null,
      users,
      roles,
      observer: null,
      limit: 10,
      search: '',
    }
  },
  computed: {
    usersFiltered() {
      return this.users.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    },
    usersPaginated() {
      return this.usersFiltered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.usersPaginated.length < this.usersFiltered.length
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    addDepartmentuser() {
      const id = this.add_user_id.value
      const formData = {
        department_id: router.currentRoute.params.id,
        // department_role_code: this.add_user_role_code ? this.add_user_role_code.value : null,
      }
      store
        .dispatch('app-departmentusers/addDepartmentuser', { id, formData })
        .then(() => {
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Utente aggiunto al Reparto',
              icon: 'UsersIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
    deleteDepartmentuser(id) {
      const formData = {
        department_id: null,
        // department_role_code: null,
      }
      store
        .dispatch('app-departmentusers/deleteDepartmentuser', { id, formData })
        .then(() => {
          this.selectedDepartmentuser = null
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Utente rimosso correttamente dal Reparto',
              icon: 'UsersIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
