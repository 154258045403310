import axios from '@axios'
import Api from '@/api/index'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        Api.getUsers(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        Api.getUser(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        Api.addUser(userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateUser(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/users/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    async exportCsv(ctx, formData) {
      return axios.get('/users/export-csv', { params: formData })
    },
  },
}
