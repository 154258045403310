import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDepartments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/departments', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchDepartment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/departments/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateDepartment(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/departments/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addDepartment(ctx, departmentData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/departments', departmentData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteDepartment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/departments/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
