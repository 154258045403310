<template>
  <b-card>
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-form
      ref="form"
      class="p-2"
    >
      <b-row>
        <b-col
          class="mb-4 d-flex justify-content-center align-items-center"
          cols="12"
        >
          <feather-icon
            icon="UsersIcon"
            size="25"
            class="mr-2"
          />
          <h1>Modifica Reparto</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Nome Reparto *"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="departmentData.name"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="departments && departments.length > 0"
          class="mb-2"
          cols="12"
          md="6"
          lg="6"
        >
          <b-form-group
            label="Reparto di Appartenenza"
            label-for="parent_department_id"
          >
            <b-form-select
              id="parent_department_id"
              v-model="departmentData.parent_department_id"
              :options="departments"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="text-right mt-2"
          cols="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateDepartment"
          >
            <b-spinner
              v-if="loading"
              variant="light"
              small
            />
            <span v-if="!loading">Salva Cambiamenti</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="reset"
          >
            Pulisci
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormSelect, BButton, BLink, BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BLink,
    BSpinner,
  },
  props: {
    departmentData: {
      type: Object,
      required: true,
    },
    departments: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    reset() {
      this.$refs.form.reset()
    },
    updateDepartment() {
      const valid = this.validation()
      if (!valid) return
      this.loading = true
      const { id } = router.currentRoute.params
      const formData = {
        name: this.departmentData.name,
        parent_department_id: this.departmentData.parent_department_id,
      }
      store
        .dispatch('app-departments/updateDepartment', { id, formData })
        .then(() => {
          router.replace({ name: 'apps-departments-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Reparto #${id} modificato con successo`,
                icon: 'UsersIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
    },
    validation() {
      if (!this.departmentData.name) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Nome',
            icon: 'AlertTriangleIcon',
            text: 'Inserire il Nome del Reparto per proseguire.',
            variant: 'danger',
          },
        })
        return false
      }
      return true
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
</style>
