<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="departmentData === undefined">
      <h4 class="alert-heading">Errore dati Reparto</h4>
      <div class="alert-body">
        Nessun Reparto trovato con questo ID. Controlla
        <b-link class="alert-link" :to="{ name: 'apps-departments-list' }"> Lista Reparti </b-link>
        per altri Reparti.
      </div>
    </b-alert>

    <template v-if="departmentData" pills>
      <!-- Tab: Information -->
      <b-row>
        <b-col cols="12">
          <departments-edit-tab-information :department-data="departmentData" :departments="departments" />
        </b-col>
        <b-col cols="12">
          <departmentusers-list :canManage="true" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { BCard, BAlert, BLink, BCol, BRow } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import departmentsStoreModule from '../departmentsStoreModule'
import DepartmentusersList from '@/views/apps/departmentusers/departmentusers-list/DepartmentusersList.vue'
import DepartmentsEditTabInformation from './DepartmentsEditTabInformation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BCol,
    BRow,

    DepartmentusersList,
    DepartmentsEditTabInformation,
  },
  setup() {
    const toast = useToast()

    const departmentData = ref(null)

    const DEPARTMENTS_APP_STORE_MODULE_NAME = 'app-departments'

    // Register module
    if (!store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEPARTMENTS_APP_STORE_MODULE_NAME, departmentsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENTS_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENTS_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('app-departments/fetchDepartment', { id: router.currentRoute.params.id })
      .then((response) => {
        departmentData.value = response.data
      })
      .catch((error) => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          router
            .replace({ name: 'auth-login' })
            .then(() => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sessione scaduta',
                  icon: 'AlertTriangleIcon',
                  text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
                  variant: 'danger',
                },
              })
            })
            .catch(() => {})
        }
      })

    const departments = ref(null)
    const _departments = [
      {
        value: null,
        text: 'Seleziona un Reparto',
      },
    ]
    store
      .dispatch('app-departments/fetchDepartments', { root: true })
      .then((response) => {
        response.data[0].forEach((department, i) => {
          if (department.id != router.currentRoute.params.id) {
            const _department = {
              value: department.id,
              text: '#' + department.id + ' - ' + department.name,
            }
            _departments.push(_department)
          }
        })
      })
      .catch((error) => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          router
            .replace({ name: 'auth-login' })
            .then(() => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sessione scaduta',
                  icon: 'AlertTriangleIcon',
                  text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
                  variant: 'danger',
                },
              })
            })
            .catch(() => {})
        }
      })
    departments.value = _departments

    return {
      departmentData,
      departments,
    }
  },
}
</script>

<style></style>
